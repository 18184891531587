/* eslint-disable */
import "firebase/firestore"
import state from "@/store/modules/moods/state"
import mutations from "@/store/modules/moods/mutations"
import actions from "@/store/modules/moods/actions"
import getters from "@/store/modules/moods/getters"

const moods = {
    namespaced: true,
    //
    // STATE
    //
    state: state,
    //
    // MUTATIONS
    //
    mutations: mutations,
    //
    // ACTIONS
    //
    actions: actions,
    //
    // GETTERS
    //
    getters: getters
}

export default moods;