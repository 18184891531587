
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';

import { defineComponent } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import { home, informationCircleOutline } from 'ionicons/icons';
import libStore from "@/lib/libStore";

export default defineComponent( {
  name: 'Home',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet
  },
  setup() {
    const { goToLogin } = libStore();
    onIonViewWillEnter(() => {
      goToLogin();
    });
    return {
      home,
      informationCircleOutline,
    }
  }
})
