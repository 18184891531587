
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  computed: {
    appClass() {
      return (!isPlatform('hybrid'))? 'webApp' : 'hybridApp'
    }
  }

});
