
const getters = {
    error: (state: { error: any }) => {
        return state.error
    },
    loading: (state: { loading: boolean }) => {
        return state.loading
    },
    list: (state: { list: any }) => {
        return state.list
    },
    userId: (state: { userId: string }) => {
        return state.userId
    },
    filterMood: (state: { filterMood: string }) => {
        return state.filterMood
    },
    filterPeriod: (state: { filterPeriod: string }) => {
        return state.filterPeriod
    }
};

export default getters;