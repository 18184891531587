import Moods from "@/lib/const";

const state = {
    list: [],
    loading: false,
    error: null,
    userId: '',
    userType: '',
    filterMood: Moods.BOTH,
    filterPeriod: Moods.DAY
};

export default state