
import { IonImg } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogoContainer',
  props: {
    moto: {
      type: Boolean,
      default: false
    },
  },
  components: {
    IonImg
  }
});
