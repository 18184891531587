import firebase from "firebase";
import moment from "moment";
import Moods from "@/lib/const";
import dateFormat from "dateformat";

function getUserType() {
    return new Promise((resolve) => {
        const user = firebase.auth().currentUser;
        if (user) {
            firebase.database().ref(`/roles/${user.uid}`).once('value').then(snapshot => {
                const role = snapshot.val();
                if(role && role.type) {
                    resolve({type: role.type, uid: user.uid})
                } else {
                    resolve({type: Moods.TYPE_USER, uid: user.uid})
                }
            });
        } else {
            resolve({})
        }
    })
}

function getUserDbRef(uid: any, type: any) {
    if (type === Moods.TYPE_OWNER) {
        return `/ownerProfile/${uid}/statusList`;
    } else {
        return `/userProfile/${uid}/statusList`
    }
}

const actions = {
    initUserType({ commit, dispatch }: any) {
        getUserType().then((value: any) => {
            commit('setUserType', value.type);
            dispatch('getMoodList');
        })
    },
    getMoodList({ commit, state }: any) {
        commit("setLoading", true);
        const user = firebase.auth().currentUser;
        if (user && state.userType) {
            const list: firebase.database.DataSnapshot[] = [];
            firebase.database().ref(getUserDbRef(user.uid, state.userType))
                .orderByChild("timestampStart")
                .startAt(Moods.p2t(state.filterPeriod))
                .once('value')
                .then((dataSnapshot) => {
                    dataSnapshot.forEach(snap => {
                        if (state.filterMood === Moods.BOTH) {
                            list.push({... snap.val(), id: snap.key});
                        }
                        if (state.filterMood === Moods.HAPPY && snap.val().name === Moods.HAPPY) {
                            list.push({... snap.val(), id: snap.key});
                        }
                        if (state.filterMood === Moods.SAD && snap.val().name === Moods.SAD) {
                            list.push({... snap.val(), id: snap.key});
                        }
                    })
                    commit("setList", list.reverse());
                }).catch((err) => {
                commit("setError", err)
            })
        }
        else {
            commit("setError", {message: 'User Not Found (1)'})
        }
    },
    async addMood({ commit, state, dispatch }: any, payload: {name: string; extra: string}) {
        commit("setLoading", true);
        const user = firebase.auth().currentUser;
        if (user) {
            await dispatch('updateLastMoodTimestampEnd')
            firebase.database().ref(getUserDbRef(user.uid, state.userType))
                .push({
                    name: payload.name,
                    dateStart: dateFormat(new Date(), "ddd mmm dd, yyyy HH:MM TT"),
                    timestampStart: moment().unix(),
                    extra: payload.extra,
                    dateEnd: null,
                    timestampEnd: null
                })
                .then((snap) => {
                    commit("setLoading", false);
                })
                .catch((err) => {
                    commit("setError", err)
                })
        }
        else {
            commit("setError", {message: 'User Not Found (2)'})
        }
    },
    updateMood({ commit, state, dispatch }: any, payload: {name: string; extra: string; id: string}) {
        commit("setLoading", true);
        const user = firebase.auth().currentUser;
        if (user) {
            firebase.database().ref(getUserDbRef(user.uid, state.userType))
                .child(payload.id)
                .update({
                    name: payload.name,
                    extra: payload.extra,
                })
                .then(() => {
                    dispatch('getMoodList');
                })
                .catch((err) => {
                    commit("setError", err)
                })
        }
        else {
            commit("setError", {message: 'User Not Found (3)'})
        }
    },
    updateLastMoodTimestampEnd({ commit, state }: any) {
        return new Promise((resolve) => {
            const user = firebase.auth().currentUser;
            if (user) {
                firebase.database().ref(getUserDbRef(user.uid, state.userType))
                .limitToLast(1)
                .once('value')
                .then((dataSnapshot) => {
                    if (dataSnapshot.numChildren() === 0) {
                        resolve(true);
                    }
                    dataSnapshot.forEach(snap => {
                        if (snap.key != null) {
                            firebase.database().ref(getUserDbRef(user.uid, state.userType))
                            .child(snap.key)
                            .update({
                                timestampEnd: moment().unix(),
                                dateEnd: dateFormat(new Date(), "ddd mmm dd, yyyy HH:MM TT")
                            }) .then(() => {
                                resolve(true);
                            }).catch((err) => {
                                commit("setError", err)
                            })
                        }
                    });
                })
            }
        })
    }
};

export default actions;