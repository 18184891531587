import moment from "moment";

export default {
    DAY: '1d',
    WEEK: '1w',
    MONTH: '1m',
    YEAR: '1y',
    HAPPY: "Happy",
    SAD: "Unhappy",
    BOTH: "Both",
    TEXT_BOTH: 'Both',
    TEXT_HAPPY: 'Happy',
    TEXT_SAD: 'Unhappy',
    DAY_TEXT: 'Last 24h',
    WEEK_TEXT: 'Last Week',
    MONTH_TEXT: 'Last Month',
    YEAR_TEXT: 'Last Year',
    TYPE_ADMIN: 'admin',
    TYPE_OWNER: 'owner',
    TYPE_USER: 'user',
    p2t: function (period: string) {
        let date: number;
        switch (period) {
            case this.WEEK:
                date = moment().subtract(7, "days").unix();
                break;
            case this.MONTH:
                date = moment().subtract(1, "months").unix();
                break;
            case this.YEAR:
                date = moment().subtract(1, "years").unix();
                break;
            default:
                date = moment().subtract(1, "days").unix();
        }
        return date;
    }
}