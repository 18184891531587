
const mutations = {
    setLoading(state: { error: any; loading: any }, payload: any) {
        state.loading = payload
        state.error = null
    },
    setError(state: { list: any; error: any; loading: any }, payload: any) {
        state.error = payload
        state.loading = false
        state.list = []
    },
    setList(state: { list: any; error: any; loading: any }, payload: any) {
        state.list = payload
        state.loading = false
        state.error = null
    },
    setUserId(state: { userId: any; list: any; error: any; loading: any }, payload: any) {
        state.userId = payload
        state.list = []
        state.loading = false
        state.error = null
    },
    setUserType(state: { userType: any }, payload: any) {
        state.userType = payload
    },
    setFilterMood(state: { filterMood: string }, payload: any) {
        state.filterMood = payload
    },
    setFilterPeriod(state: { filterPeriod: string }, payload: any) {
        state.filterPeriod = payload
    },
};

export default mutations