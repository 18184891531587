<template>
  <ion-app id="myApp" :class="appClass">
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { isPlatform } from '@ionic/vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  computed: {
    appClass() {
      return (!isPlatform('hybrid'))? 'webApp' : 'hybridApp'
    }
  }

});
</script>
<style>
#myApp.webApp {
  max-width: 450px;
  margin: 15px auto;
  padding: 12px;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2),0 12px 17px 2px rgba(0,0,0,.14),0 5px 22px 4px rgba(0,0,0,.12)!important;
}
</style>