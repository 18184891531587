
import {
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonContent,
  IonButton
} from '@ionic/vue';

import { defineComponent } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import LogoContainer from '@/components/LogoContainer.vue';
import { personOutline, lockClosed, star } from 'ionicons/icons';
import libStore from "@/lib/libStore";
import openToast from "@/lib/toast";

export default defineComponent({
  name: 'Login',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonInput,
    IonItem,
    IonButton,
    IonContent,
    IonPage,
    LogoContainer
  },
  setup () {
    const { authErrorMessage, store, goToHome } = libStore();
    onIonViewWillEnter(() => {
      goToHome();
    });
    return {
      store,
      authErrorMessage,
      goToHome,
      star,
      personOutline,
      lockClosed
    }
  },
  data() {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    async doLogin() {
      try {
        const user = await this.store.dispatch('user/userLogin', {email: this.email, password: this.password});
        if (user === false) {
          await openToast(this.authErrorMessage());
        } else {
          await openToast("Logged In Successfully");
          this.goToHome();
        }
      } catch (e) {
        await openToast(this.authErrorMessage(e));
      }
    }
  }
});
