import { createStore } from 'vuex'
import user from './modules/user'
import moods from './modules/moods'
import firebase from "firebase/app";
import "firebase/firestore";

firebase.initializeApp({
    // PROD
    apiKey: "AIzaSyCaLJQgDG_bmH9cUPYoMko_th_42wmeAZA",
    authDomain: "happinessbank-8f918.firebaseapp.com",
    databaseURL: "https://happinessbank-8f918.firebaseio.com",
    projectId: "happinessbank-8f918",
    storageBucket: "happinessbank-8f918.appspot.com",
    messagingSenderId: "182223425726",
    appId: "1:182223425726:web:83c874d3258aea26e14eef"
    // DEV
    // apiKey: "AIzaSyDSPuapyr-oWiCYnke0LC7Rfbzfxi2ewX8",
    // authDomain: "oliverapp-a39f9.firebaseapp.com",
    // databaseURL: "https://oliverapp-a39f9.firebaseio.com",
    // projectId: "oliverapp-a39f9",
    // storageBucket: "oliverapp-a39f9.appspot.com",
    // messagingSenderId: "445146099705"
});

const store = createStore({
    modules: {
        user,
        moods
    },
})

export default store
